
/* Styles pour la section FAQ */
/* Ajoutez ou modifiez la classe du conteneur principal de la FAQ */
.faq-container {
    width: 800px; /* Largeur fixe */
    margin: 0 auto; /* Centrage horizontal */
    padding: 20px;
    box-sizing: border-box;
  }
  
  /* Assurer la responsivité sur les petits écrans */
  @media (max-width: 768px) {
    .faq-container {
      width: 90%; /* Ajustement de la largeur */
    }
  }
  
  .faq-item {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-weight: bold;
    position: relative;
  }
  
  .faq-toggle {
    font-size: 24px;
    line-height: 1;
  }
  
  .faq-answer {
    margin-top: 10px;
    padding-left: 10px;
    color: #555;
  }
  
  /* Effet de survol pour les questions */
  .faq-question:hover {
    color: #4285F4;
  }
  
  /* Transition pour une ouverture fluide */
  .faq-answer {
    animation: fadeIn 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }