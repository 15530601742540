:root {
  --primary-color: #fbf9f3;
  --primary-text-color: #000000;
  --secondary-text-color: #be6356;
  --secondary-color: #0f0944;
  --title-font: 'Roboto';
  --text-font: 'Open Sans';
  --romantic-font: 'Pinyon Script';

}

.default {
  padding-top: 0;
  background-color: #fbf9f3;
  font-family: var(--text-font);

}

.content {
  padding-top: 150px;
}
