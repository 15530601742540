.home-div {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
}

.section-title {
  font-weight: bold;
  font-size: 1.5em;
  margin: 20px 0;
  margin-bottom: 30px;
  text-align: center;
}

.section-div {
  width: 100%;
  max-width: 800px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;;
  will-change: opacity, transform;
  transition: opacity 0.5s ease, transform 0.5s ease; /* For smooth scrolling */  
  scroll-margin-top: 100px; /* For smooth scrolling with space for the banner */
}

.section-text {
  font-size: 1em;
  line-height: 1.6;
}

.tiles-container {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 40px 0;
}

.tile {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 10px;
  flex: 1 1 200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 8px;
}

.tile:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.tile .material-symbols-outlined {
  font-size: 2em;
  margin-bottom: 10px;
}

.small-text {
  font-size: 0.9em;
  color: #555555;
}

.programme-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.programme-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.programme-row.reverse {
  flex-direction: row-reverse;
}

.programme-image img {
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

.programme-text {
  flex: 1;
}

.programme-text h4 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .programme-row,
  .programme-row.reverse {
    flex-direction: column;
  }

  .programme-image img {
    max-width: 100%;
  }
  .programme-text img {
    max-width: 100%;
  }

  .section-div {
    opacity: 1;
    transform: none;
    transition: none;
    will-change: auto;
  }

}

.gmaps-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #be6356;;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.gmaps-button:hover {
  background-color: #bd3632;
}

.center-text {
  text-align: center; /* Centre le contenu textuel */
}

.title {
  position: relative;
  top: 0;
  width: 100%;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: calc(1rem + 1vw);
  margin-bottom: 50px;
}

.title h1 {
  font-size: 4rem;
  color: #be6356;
  margin: 0;
}

.title h2 {
  font-size: 1.5rem;
  color: #333;
  margin: 0;
}

.third-of-page {
  height: 30vh; /* 30% de la hauteur de la fenêtre */
}