h1 {
  color: #be6356; /* White color for the text */
  font-family: 'Roboto', sans-serif;
  font-size: 5em; /* Larger font size */
  width: 100%; /* Ensure h1 takes full width of its container */
}

h2 {
  color: #be6356;
  font-family: 'Roboto', sans-serif;
  font-size: 2em;
  width: 100%;
}

.text {
  color: var(--secondary-text-color); /* Light grey color for the text */
  font-family: 'Open Sans', sans-serif;

  font-size: 1.5em; /* Larger font size */
  width: 100%; /* Ensure text takes full width of its container */
}

.banner-content {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.banner {
  position: fixed; /* Assure que la bannière reste fixe */
  top: 0;
  width: 100%;
  z-index: 1001; /* Augmente le z-index pour qu'elle soit au-dessus */
  /* Vérifiez qu'aucune autre propriété ne masque la bannière */
  background-color: #fbf9f3; /* Définit la couleur de fond de la bannière */
}

.body-padding-top {
  padding-top: 60px; /* Ajustez cette valeur à la hauteur de votre bannière */
}

.left-content h2 {
  font-size: 2em;
  color: #be6356;
}

.left-content:hover {
  transform: scale(1.1);
  text-shadow: 0.5px 0.5px 0.5px #ffffff89;
}

.right-content {
  display: flex;
  gap: 10px;
}

.box {
  color: #be6356;
  padding: 10px;
  cursor: pointer;
}

/* Les styles de `.title` ne sont plus nécessaires et les commentaires associés ont été retirés */